@font-face {
    font-family: 'Inter';
    src: url('/public/assets/fonts/Inter-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.portalBody {
    font-family: "Inter", sans-serif !important;
    position: relative;
    max-width: 1380px;
    margin-inline: auto;
    margin-top: 35px;
}

.portalBody.headerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
}

.weAreHere {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.headerContainer 
.helpContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #176FFF;
    border-radius: 39px;
    background-color: #FFF;
    width: 208px;
    justify-content: center;
    cursor: pointer;
}

.cardStyles {
    box-sizing: border-box;
    width: 264px;
    border-radius: 20px;
    border: 1px solid #EBECF0;
    background-color: #FFF;
    padding: 16px;
    transition: 0.2s;
}

.cardStyles:hover {
    background-color: #176FFF;
    cursor: pointer;
    transition: 0.2s;
}

.cardsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.pageTitle {
    color: #333;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.pageDescription {
    color: #828A92;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    width: 536px;
}

.instructor {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    position: relative;
    transition: 0.2s;
}

.cardStyles:hover .instructor {
    color: #FFF;
    transition: 0.2s;
}

.cardStyles:hover .instructor::after,
.cardStyles:hover .student::after {
    content: url('../public/assets/images/rightArrowIcon.svg');
    position: absolute;
    right: 0;
    transition: 0.2s;
}

.student {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: 44px;
    position: relative;
    transition: 0.2s;
}

.cardStyles:hover .student {
    color: #FFF;
    transition: 0.2s;
}

.description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 81px;
}

.image {
    margin-bottom: 20px;
}

.textIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.icon {
    margin-top: 25px;
}

.tableIcon {
    margin-bottom: 20px;
}

.tableIcon path {
    fill: #333;
    transition: 0.2s;
}

.cardStyles:hover .tableIcon path {
    fill: #FFF;
    transition: 0.2s;
}

.cardStyles:hover .studentIcon path {
    fill: #FFF;
    transition: 0.2s;
}