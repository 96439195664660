.HelpModal {
    :global(.ant-modal-content) {
        border-radius: 20px;
        height: 457px !important;
    }
}

.modalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.headerTitle {
    color: var(--Colors-Gray-G2, #828A92);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
}

.headerQuestion {
    color: var(--Colors-Main-Colors-P3, #333);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin-top: -7px;
}

.cardStyle {
    border-radius: 20px;
    border: 1px solid var(--Colors-Gray-G4, #EBECF0);
    background-color: var(--Colors-Main-Colors-P2, #FFF);
    padding: 17px 16px 10px 17px;
    height: 133px;
    margin-top: 83px;
}


.cardStyle:hover {
    border: 1px solid #176FFF;
    background-color: #F3F8FF;
    cursor: pointer;
}

.cardsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.getInTouch {
    color: var(--Colors-Main-Colors-P3, #333);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 249px;
}

.supportEmail {
    color: var(--Colors-Gray-G2, #828A92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.useSupport {
    color: var(--Colors-Main-Colors-P3, #333);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 249px;
}

.helpLink {
    color: var(--Colors-Main-Colors-P1, #176FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

@media (max-width: 992px) {
    .cardsContainer {
        flex-direction: column;
    }

    .modalHeader {
        justify-content: flex-start;
    }

    .headerTitle {
        font-size: 14px;
    }

    .headerQuestion {
        text-align: left;
        font-size: 14px;
    }

    .cardStyle {
        margin-top: 30px;
        margin-bottom: -27px;
        height: 108px;
        width: 100%;
    }

    .mailIcon {
        width: 13px;
        height: 12px;
    }

    .earthIcon {
        width: 13px;
        height: 13px;
    }

    .useSupport,
    .getInTouch {
        font-size: 14px;
        margin-top: 4px;
    }

    .helpLink,
    .supportEmail {
        font-size: 12px;
    }

    .helpModal {
        .ant-modal-content {
            height: 500px;
            padding: 41px;
        }
    }
}