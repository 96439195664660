.loader {
    position: fixed;
    transform: translate(-50%, -50%) rotate(180deg); /* Set initial rotation state */
    animation: halfSpin 5s linear infinite;
    z-index: 999;
    }
    .loader svg {
      width: 50px !important;
      height: 50px !important;
    }
  
    @keyframes halfSpin {
      0% {
        transform: rotate(180deg);
      }
  
      50% {
        transform: rotate(0deg);
      }
  
      100% {
        transform: rotate(180deg);
      }
    }
  .centerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px); 
    width: calc((100vw ) /2);
    margin: auto;
  
  }